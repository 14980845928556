
@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: "Great";
    src: url("./assets//fonts/Great.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
@font-face {
    font-family: "Pacifico";
    src: url("./assets//fonts/Pacifico.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
@font-face {
    font-family: "Flower";
    src: url("./assets//fonts/Flower.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }


body{
    background: "white"
}
.label-headline{
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    padding: 2px;
}

.label-info{
    background-color: #337ab7;
    padding:3px 8px;
    color:white;
    border-radius: 5px;
}

.custom-row{
    display:flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
}
.dropdown-item a{
    color:black !important;
    text-decoration: none;
}
.dropdown-item:nth-child(even){
    background-color:aliceblue
}

.btn-info {
    color: #000;
    background-color: white;
    border-color: grey;
    margin:3px;
    padding:10px;
}


.bg-light {
    background-color: white !important;
}

.text-gradient {
    background: linear-gradient(to right, #109aea, #57024a, #57024a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 900;
  }
.dropdown button {
    display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
}